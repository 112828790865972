import * as React from 'react';
import Layout from '../Layout';
import { PageContextProps, StrapiLoanAmountType } from '../../utilities/types';
import { useStrapiStore } from '../../store/useStrapiStore';
import {
  Cards,
  Rewards,
  Interest,
  Airtime,
  Friends,
  Testimonials,
  Faqs,
  LoanHero,
  ApiResponseLayout,
} from '../../components';
import { formatAmount, replacePlaceholders } from '../../utilities';

const LoanAmounts = ({ pageContext }: PageContextProps) => {
  if (!pageContext || !pageContext.slug) return null;

  const { homepageData, homepageState, seoBlock, seoBlockState } =
    useStrapiStore((state) => ({
      homepageData: state.homepageData,
      homepageState: state.homepageState,
      seoBlockState: state.seoBlockState,
      seoBlock: state.seoBlock,
    })) as StrapiLoanAmountType;

  const errorState =
    !homepageData ||
    !seoBlock ||
    !homepageData.data ||
    homepageState === 'error' ||
    !seoBlock.data ||
    seoBlockState === 'error';

  const loadingState =
    homepageState === 'loading' || seoBlockState === 'loading';

  if (loadingState) return <ApiResponseLayout apiResponse="loading" />;

  if (errorState) {
    return <ApiResponseLayout apiResponse="error" />;
  }

  const {
    loan_offer_header,
    hero_text_2,
    sub_hero_text_2,
    hero_text_3,
    sub_hero_text_3,
    hero_text_4,
    sub_hero_text_4,
    hero_text_5,
    sub_hero_text_5,
  } = homepageData.data.attributes;

  const { hero_text, hero_sub_text, meta_title, meta_description } =
    seoBlock.data.attributes;

  const wordsToReplace = {
    amount: formatAmount(pageContext.amount),
    period: pageContext.period,
    period_type: pageContext.period_type,
  };

  const refinedHeroText = replacePlaceholders(hero_text, wordsToReplace);
  const refinedSubHeroText = replacePlaceholders(hero_sub_text, wordsToReplace);

  return (
    <Layout
      title={replacePlaceholders(meta_title, wordsToReplace)}
      description={replacePlaceholders(meta_description, wordsToReplace)}
    >
      <div className="home">
        <LoanHero heroText={refinedHeroText} subHeroText={refinedSubHeroText} />
        <Cards loanOfferHeader={loan_offer_header} />
        <Rewards heroText={hero_text_2} subHeroText={sub_hero_text_2} />
        <Interest heroText={hero_text_3} subHeroText={sub_hero_text_3} />
        <Airtime heroText={hero_text_4} subHeroText={sub_hero_text_4} />
        <Friends heroText={hero_text_5} subHeroText={sub_hero_text_5} />
        <Testimonials />
        <Faqs />
      </div>
    </Layout>
  );
};
export default LoanAmounts;
